import { MedicineBoxOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams, Switch, Route, Redirect } from 'react-router-dom';

import PageWithSectionsContainer from '../../components/PageWithSectionsContainer';
import PageWithSectionsContent from '../../components/PageWithSectionsContent';
import PageWithSectionsHeader from '../../components/PageWithSectionsHeader';
import SideMenu from '../../components/SideMenu/SideMenu';
import { Spinner } from '../../components/Spinner';
import { useRootStore } from '../../context/RootStoreContext';
import { CareProviderAnnouncements } from '../Announcements/careProvider/CareProviderAnnouncements';
import { OpeningHoursCareProvider } from '../CareUnits/OpeningHours';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

export const CareProvider = () => {
  const { careProviderId } = useParams<{ careProviderId: string }>();
  const { careUnitsStore, partnersStore } = useRootStore();
  const intl = useIntl();

  const subRoutes = useMemo(() => {
    const routes = ['announcements'];
    if (partnersStore.partnerCustomizations.get('ENABLE_OPENING_HOURS_IN_ADMIN')) {
      routes.push('opening-hours');
    }
    return routes;
  }, []);

  useEffect(() => {
    if (careProviderId && careUnitsStore.currentCareProvider?.id !== careProviderId) {
      careUnitsStore.fetchCareProvider(careProviderId);
    }
  }, [
    careUnitsStore.currentCareProvider,
    careUnitsStore.fetchCareProvider,
    careProviderId,
    careUnitsStore,
  ]);

  const location = useLocation();
  // callback so we can get currentCareProvider?.name once the careUnitsStore is loaded in Observer
  const getBreadcrumbs = useCallback(() => {
    const breadcrumbs = [
      {
        icon: <MedicineBoxOutlined />,
        text: <FormattedMessage id="care-providers-and-care-units" />,
        link: '/care-providers',
      },
      {
        text: careUnitsStore.currentCareProvider?.name || careProviderId,
        link: `/care-providers/${careProviderId}`,
      },
    ];

    const matchingSubRoute = subRoutes.find(route => location.pathname.includes(route));
    if (matchingSubRoute) {
      breadcrumbs.push({
        text: intl.formatMessage({ id: `care-units.${matchingSubRoute}` }),
        link: `/care-providers/${careProviderId}/${matchingSubRoute}`,
      });
    }

    return breadcrumbs;
  }, [careProviderId, careUnitsStore.currentCareProvider?.name, intl, location.pathname]);

  const links = useMemo(
    () =>
      subRoutes.map(route => ({
        url: `/care-providers/${careProviderId}/${route}`,
        id: `/care-providers/${careProviderId}/${route}`,
        content: intl.formatMessage({ id: `care-units.${route}` }),
      })),
    [careProviderId, intl]
  );

  return (
    <Observer>
      {() => {
        if (careUnitsStore.isLoading()) {
          return <Spinner />;
        }

        return (
          <Fragment>
            <PartnerStatus />
            <PageWithSectionsHeader
              breadcrumbs={getBreadcrumbs()}
              title={
                <>
                  <FormattedMessage id="care-providers-and-care-units" />:{' '}
                  {careUnitsStore.currentCareProvider?.name}
                </>
              }
            />
            <PageWithSectionsContainer>
              <SideMenu links={links} />
              <PageWithSectionsContent>
                <Switch>
                  <Route
                    path="/care-providers/:careProviderId/opening-hours"
                    component={OpeningHoursCareProvider}
                  />
                  <Route
                    path="/care-providers/:careProviderId/announcements"
                    component={CareProviderAnnouncements}
                  />
                  <Redirect
                    from="/care-providers/:careProviderId"
                    to="/care-providers/:careProviderId/opening-hours"
                  />
                </Switch>
              </PageWithSectionsContent>
            </PageWithSectionsContainer>
          </Fragment>
        );
      }}
    </Observer>
  );
};
